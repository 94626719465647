// https://www.vuemastery.com/courses/next-level-vue/mixins/
import { db } from "@/main";
import { addDaysToDate } from "../helpers/dateTimeConvertingFunctions";

export const userDataMixin = {
  data() {
    return {
      //   currentUserData: {},
      //   manager: false,
      //   admin: false,
      //   aider: false,
      //   sudo: false,
      //   blocked: false,
    };
  },
  created() { },

  methods: {
    getCurrentUserData(userId) {
      if (userId == null) {
        return;
      }
      //get user
      let userData = {};

      //get from db
      if (localStorage.getItem(userId + "-userdata")) {
        try {
          userData = JSON.parse(localStorage.getItem(userId + "-userdata"));
          this.updateRolesAndData(userData);
        } catch (e) {
          console.log(e);
        }
      } else {
        console.log("mixin doesnt have local storage data. do nothing.");
        // userData["displayName"] = "system user";
        // userData["role"] = "[]";
        // userData["userId"] = "systemUser";
      }
      //  this.updateRolesAndData(userData);
    },
    updateRolesAndData(userData) {
      this.$root.currentUserData = Object.assign({}, userData);
      const userRole = userData.role;
      if (userRole.indexOf("admin") !== -1) {
        this.$root.admin = true;
      }
      if (userRole.indexOf("manager") !== -1) {
        this.$root.manager = true;
      }
      if (userRole.indexOf("aider") !== -1) {
        this.$root.aider = true;
      }
      if (userRole.indexOf("sudo") !== -1) {
        this.$root.sudo = true;
      }
      if (
        !this.$root.sudo &&
        !this.$root.admin &&
        !this.$root.manager &&
        !this.$root.aider
      ) {
        this.$root.blocked = true;
      }
    },
    async logActivity(section, user, activity) {
      let expireAt = addDaysToDate(new Date(), 90);
      let addLog = {
        datetime: new Date(),
        expireAt: expireAt,
        section: section,
        user: user,
        activity: activity,
        scversion: this.$root.scversion,
        dateKey: new Date().toISOString().substring(0, 10),
        monthKey: new Date().toISOString().substring(0, 7),
      };
      const id = this.getIdString() + Date.now();
      await db
        .collection("x-logs")
        .doc(id)
        .set(addLog, { merge: true });
    },
    getIdString() {
      return Math.random()
        .toString(36)
        .slice(2);
    },
  },
};
