import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import { env } from "@/helpers/firebaseConfig.js";

// MAMA-OK brandbook https://ej.uz/37sp
let colorTheme = {
  primary: "#827090",
  secondary: "#DED9E1",
  accent: "#46687d",
  info: "#46687d", // accent
  success: "#827090", // primary
};

// if (env == "testing") {
//   colorTheme = {
//     primary: "#BF4440",
//     secondary: "#9A754F",
//     accent: "#C7615E",
//     info: "#C7615E", // accent
//     success: "#9CCC65", // light green
//   };
// }

Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: "md", // default - only for display purposes
  },
  theme: {
    themes: {
      light: colorTheme,
      dark: colorTheme,
    },
  },
};

export default new Vuetify(opts);
