export const config = {
  // apiKey: "AIzaSyDqNLLBHIcEVGQoC1Ga8njsOkF66mP_m1E",
  // authDomain: "mamaokapp.firebaseapp.com",
  // databaseURL: "https://mamaokapp.firebaseio.com",
  // projectId: "mamaokapp",
  // storageBucket: "mamaokapp.appspot.com",
  // messagingSenderId: "181813930375",
  // appId: "1:181813930375:web:a2ae4c99d06c433a",
  // measurementId: "G-62JE3GRKTF",
  apiKey: "AIzaSyDroyJPEu1TCARXGUTgcuoecptcwm0k2s4",
  authDomain: "mok-test-be2d0.firebaseapp.com",
  databaseURL: "https://mok-test-be2d0.firebaseio.com",
  projectId: "mok-test-be2d0",
  storageBucket: "mok-test-be2d0.appspot.com",
  messagingSenderId: "537760425840",
  appId: "1:537760425840:web:de6631d3f71f4a56",
  measurementId: "G-TB07F34KR6",
};
