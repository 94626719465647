import { i18n } from '../language/i18n';

export const repositioningTaskId = "zWJAFqWMXkIbIvkPG7nL";

export const workersSuggestionsStatuses = [
  {text: i18n.t("general.submitted"), value: "submitted", icon: "pending_actions", color: "warning"},
  {text: i18n.t("general.accepted"), value: "accepted", icon: "thumb_up_alt", color: "success"},
  {text: i18n.t("general.declined"), value: "declined", icon: "thumb_down_alt", color: "error"},
];

export const nbhShiftsToTxt = {
  0: `${i18n.t("visits.visit-nr")} 1`,
  1: `${i18n.t("visits.visit-nr")} 2`,
  2: `${i18n.t("visits.visit-nr")} 3`,
  3: `${i18n.t("visits.visit-nr")} 4`,
  4: `${i18n.t("visits.visit-nr")} 5`,
  5: `${i18n.t("visits.visit-nr")} 6`,
};
export const bfhShiftsToTxt = {
  0: "Nakts",
  1: "Rīts",
  2: "Vakars",
};

export const daysToTxt = () => {
  const locale = i18n.locale;
  return {
    0: i18n.t("general.weekdays.0", locale),
    1: i18n.t("general.weekdays.1", locale),
    2: i18n.t("general.weekdays.2", locale),
    3: i18n.t("general.weekdays.3", locale),
    4: i18n.t("general.weekdays.4", locale),
    5: i18n.t("general.weekdays.5", locale),
    6: i18n.t("general.weekdays.6", locale),
  }
};

export const statusToTxt = () => {
  const locale = i18n.locale;
  return {
    open: i18n.t("tasks.task-status.open", locale),
    independent: i18n.t("tasks.task-status.independent", locale),
    assisted: i18n.t("tasks.task-status.assisted", locale),
    supervised: i18n.t("tasks.task-status.supervised", locale),
    canceled: i18n.t("tasks.task-status.canceled", locale),
    postponed: i18n.t("tasks.task-status.postponed", locale),
    doneIdr: i18n.t("tasks.task-status.doneIdr", locale),
  }
};

export const chartDataObj = {
  open: 0,
  postponed: 0,
  canceled: 0,
  doneIdr: 0,
  independent: 0,
  supervised: 0,
  assisted: 0,
};

export const CustomBtnTypes = {
  regularBtn: "regularBtn",
  textBtn: "textBtn",
  iconBtn: "iconBtn",
}

export const statObj = [
  {
    type: "bar",
    label: "Neatzīmēti",
    data: [],
    backgroundColor: "#EF5350",
    fill: false,
  },
  {
    type: "bar",
    label: "Pārcelti",
    backgroundColor: "#B0BEC5",
    data: [],
  },
  {
    type: "bar",
    label: "Atcelti",
    backgroundColor: "#78909C",
    data: [],
  },
  {
    type: "bar",
    label: "Netiešie",
    backgroundColor: "#80CBC4",
    data: [],
  },
  {
    type: "bar",
    label: "Patstāvīgi",
    backgroundColor: "#81C784",
    data: [],
  },
  {
    type: "bar",
    label: "Uzraudzībā",
    backgroundColor: "#43A047",
    data: [],
  },
  {
    type: "bar",
    label: "Ar palīdzību",
    backgroundColor: "#2E7D32",
    data: [],
  },
];

export const profilingCategories = {
  category1: "cognitiveValue",
  category2: "4200. Hygiene/ bathing/ grooming",
  category3: "4300. Dressing",
  category4: "4400. Eating",
  category5: "4600. Toileting/ Incontinence",
  category6: "4700. Positionning/ Bed mobility",
  category7: "4800. Moving",
};

export const incidents = [
  {
    header: i18n.t("incidents.incidents-list.header-1"),
  },
  {
    text: i18n.t("incidents.incidents-list.fall"),
    value: 'Kritiens',
    cognitive: false,
    alertAiders: false,
  },
  {
    text: i18n.t("incidents.incidents-list.arguing"),
    value: 'Strīdēšanās',
    cognitive: false,
    alertAiders: false,
  },
  {
    text: i18n.t("incidents.incidents-list.accident"),
    value: 'Negadījums',
    cognitive: false,
    alertAiders: false,
  },
  {
    text: i18n.t("incidents.incidents-list.damage-or-loss-of-property"),
    value: 'Īpašuma bojāšana vai pazušana',
    cognitive: false,
    alertAiders: false,
  },
  {
    text: i18n.t("incidents.incidents-list.abrasions-and-bruises"),
    value: 'Nobrāzumi, sasitumi',
    cognitive: false,
    alertAiders: false,
  },
  {
    text: i18n.t("incidents.incidents-list.covid"),
    value: 'Covid-19',
    cognitive: false,
    alertAiders: false,
  },
  {
    text: i18n.t("incidents.incidents-list.other"),
    value: 'Cits',
    cognitive: false,
    alertAiders: false,
  },
  {
    text: i18n.t("incidents.incidents-list.death"),
    value: 'Nāve',
    cognitive: false,
    alertAiders: false,
  },
  {
    divider: true,
  },
  {
    header: i18n.t("incidents.incidents-list.header-2"),
  },
  {
    text: `!!! ${i18n.t("incidents.incidents-list.unreasonable-walking")}`,
    value: 'Nepamatota staigāšana',
    cognitive: true,
    alertAiders: false,
  },
  {
    text: `!!! ${i18n.t("incidents.incidents-list.agression")}`,
    value: 'Agresija',
    cognitive: true,
    alertAiders: false,
  },
  {
    text: `!!! ${i18n.t("incidents.incidents-list.escape")}`,
    value: 'Bēgšana',
    cognitive: true,
    alertAiders: true,
  },
  {
    text: `!!! ${i18n.t("incidents.incidents-list.inadequate-food-use")}`,
    value: 'Neadekvāta ēdiena izmantošana',
    cognitive: true,
    alertAiders: false,
  },
  {
    text: `!!! ${i18n.t("incidents.incidents-list.inadequate-handling-of-faeces")}`,
    value: 'Neadekvātas darbības ar ekskrementiem',
    cognitive: true,
    alertAiders: false,
  },
  {
    text: `!!! ${i18n.t("incidents.incidents-list.inadequate-nudity")}`,
    value: 'Neadekvātas kailuma izmausmes',
    cognitive: true,
    alertAiders: false,
  },
  {
    text: `!!! ${i18n.t("incidents.incidents-list.disruption-of-sleep")}`,
    value: 'Neadekvātas naktsmiera traucēšana',
    cognitive: true,
    alertAiders: false,
  },
  {
    text: `!!! ${i18n.t("incidents.incidents-list.attempted-suicide")}`,
    value: 'Pašvnāvības mēģinājums',
    cognitive: true,
    alertAiders: true,
  },
];

export const vitals = {
  "Spiediens A": 0.0,
  "Spiediens Z": 0.0,
  Pulss: 0.0,
  "Skābekļa piesātinājums, %": 0.0,
  Svars: 0.0,
  Temperatūra: 0.0,
  Cukurs: 0.0,
};

export const offDayDirections = [
  { text: "client.outing", value: "out" },
  { text: "client.arrival", value: "return" },
  { text: "", value: null },
];

export const firebaseCollectionsServices = {
  bigFamilyHouses: "bfh",
  neighborhoodHouses: "neighborhood",
  mokLit: "mokLit",
}

export const tutorials = [
  {
    id: "senior-group-basics",
    title: "attestation.tutorials-titles.sg-basics",
    video: {
      bfh: "https://www.loom.com/embed/e8a9e728e11847d6a60bf69602522e61",
      neighborhood: "https://www.loom.com/embed/ba6127a4b77344fa80dd2b9da7278552",
      neighborhoodRu: "https://www.loom.com/embed/cda784abed1041888f5bcdafb1dfce29"
    },
    testName: "seniorGroupBasicsTest",
    presentations: [
      { url: "https://www.beautiful.ai/player/-Mt2Al-4-vqT1JCd31dB/AUTOMATIZATION-JOB-Candidates-2022-BFH-and-MOK-First-look", service: "bfh" },
      { url: "https://www.beautiful.ai/player/-N-S7t_GctGqVx7ymcq7/A-JOB-Candidates-2022-MOK-First-look-LABOTA-VERSIJA", service: "neighborhood" }
    ],
    test: [
      {
        question: "attestation.user-tests-list.senior-group-basics.0.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.senior-group-basics.0.answers.0" },
          { id: 1, text: "attestation.user-tests-list.senior-group-basics.0.answers.1" },
          { id: 2, text: "attestation.user-tests-list.senior-group-basics.0.answers.2" },
        ],
        correctAnswerId: 0
      },
      {
        question: "attestation.user-tests-list.senior-group-basics.1.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.senior-group-basics.1.answers.0" },
          { id: 1, text: "attestation.user-tests-list.senior-group-basics.1.answers.1" },
          { id: 2, text: "attestation.user-tests-list.senior-group-basics.1.answers.2" },
          { id: 3, text: "attestation.user-tests-list.senior-group-basics.1.answers.3" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.senior-group-basics.2.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.senior-group-basics.2.answers.0" },
          { id: 1, text: "attestation.user-tests-list.senior-group-basics.2.answers.1" },
          { id: 2, text: "attestation.user-tests-list.senior-group-basics.2.answers.2" },
        ],
        correctAnswerId: 0
      },
      {
        question: "attestation.user-tests-list.senior-group-basics.3.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.senior-group-basics.3.answers.0" },
          { id: 1, text: "attestation.user-tests-list.senior-group-basics.3.answers.1" },
          { id: 2, text: "attestation.user-tests-list.senior-group-basics.3.answers.2" }
        ],
        correctAnswerId: 2
      },
      {
        question: "attestation.user-tests-list.senior-group-basics.4.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.senior-group-basics.4.answers.0" },
          { id: 1, text: "attestation.user-tests-list.senior-group-basics.4.answers.1" },
          { id: 2, text: "attestation.user-tests-list.senior-group-basics.4.answers.2" },
        ],
        correctAnswerId: 2
      },
      {
        question: "attestation.user-tests-list.senior-group-basics.5.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.senior-group-basics.5.answers.0" },
          { id: 1, text: "attestation.user-tests-list.senior-group-basics.5.answers.1" },
          { id: 2, text: "attestation.user-tests-list.senior-group-basics.5.answers.2" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.senior-group-basics.6.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.senior-group-basics.6.answers.0" },
          { id: 1, text: "attestation.user-tests-list.senior-group-basics.6.answers.1" },
          { id: 2, text: "attestation.user-tests-list.senior-group-basics.6.answers.2" },
        ],
        correctAnswerId: 0
      },
      {
        question: "attestation.user-tests-list.senior-group-basics.7.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.senior-group-basics.7.answers.0" },
          { id: 1, text: "attestation.user-tests-list.senior-group-basics.7.answers.1" },
          { id: 2, text: "attestation.user-tests-list.senior-group-basics.7.answers.2" },
        ],
        correctAnswerId: 2
      },
      {
        question: "attestation.user-tests-list.senior-group-basics.8.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.senior-group-basics.8.answers.0" },
          { id: 1, text: "attestation.user-tests-list.senior-group-basics.8.answers.1" },
          { id: 2, text: "attestation.user-tests-list.senior-group-basics.8.answers.2" },
        ],
        correctAnswerId: 0
      },
      {
        question: "attestation.user-tests-list.senior-group-basics.9.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.senior-group-basics.9.answers.0" },
          { id: 1, text: "attestation.user-tests-list.senior-group-basics.9.answers.1" },
          { id: 2, text: "attestation.user-tests-list.senior-group-basics.9.answers.2" },
        ],
        correctAnswerId: 0
      },
    ]
  },
  {
    id: "senior-coding-app",
    testName: "seniorCodingAppTest",
    title: "attestation.tutorials-titles.sc-work-app",
    presentations: [
      {
        url: "https://docs.google.com/document/u/2/d/e/2PACX-1vR44pbR1Rgxccxx-jiu1zIPiomsZAWSKSpq0eWRW4InUI-T1k0mJ2X_XsBLEHb2ZeTC7gSVKskDeRUy/pub",
        iframeUrl: "https://docs.google.com/document/d/e/2PACX-1vR44pbR1Rgxccxx-jiu1zIPiomsZAWSKSpq0eWRW4InUI-T1k0mJ2X_XsBLEHb2ZeTC7gSVKskDeRUy/pub?embedded=true"
      },
    ],
    test: [
      {
        question: "attestation.user-tests-list.senior-coding-app.0.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.senior-coding-app.0.answers.0" },
          { id: 1, text: "attestation.user-tests-list.senior-coding-app.0.answers.1" },
          { id: 2, text: "attestation.user-tests-list.senior-coding-app.0.answers.2" },
        ],
        correctAnswerId: 2
      },
      {
        image: "group.png",
        question: "attestation.user-tests-list.senior-coding-app.1.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.senior-coding-app.1.answers.0" },
          { id: 1, text: "attestation.user-tests-list.senior-coding-app.1.answers.1" },
          { id: 2, text: "attestation.user-tests-list.senior-coding-app.1.answers.2" },
        ],
        correctAnswerId: 1
      },
      {
        image: "eye.png",
        question: "attestation.user-tests-list.senior-coding-app.2.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.senior-coding-app.2.answers.0" },
          { id: 1, text: "attestation.user-tests-list.senior-coding-app.2.answers.1" },
          { id: 2, text: "attestation.user-tests-list.senior-coding-app.2.answers.2" },
        ],
        correctAnswerId: 2
      },
      {
        image: "person.png",
        question: "attestation.user-tests-list.senior-coding-app.3.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.senior-coding-app.3.answers.0" },
          { id: 1, text: "attestation.user-tests-list.senior-coding-app.3.answers.1" },
          { id: 2, text: "attestation.user-tests-list.senior-coding-app.3.answers.2" },
        ],
        correctAnswerId: 0
      },
      {
        question: "attestation.user-tests-list.senior-coding-app.4.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.senior-coding-app.4.answers.0" },
          { id: 1, text: "attestation.user-tests-list.senior-coding-app.4.answers.1" },
          { id: 2, text: "attestation.user-tests-list.senior-coding-app.4.answers.2" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.senior-coding-app.5.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.senior-coding-app.5.answers.0" },
          { id: 1, text: "attestation.user-tests-list.senior-coding-app.5.answers.1" },
          { id: 2, text: "attestation.user-tests-list.senior-coding-app.5.answers.2" },
        ],
        correctAnswerId: 1
      },
      {
        image: "incident.png",
        question: "attestation.user-tests-list.senior-coding-app.6.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.senior-coding-app.6.answers.0" },
          { id: 1, text: "attestation.user-tests-list.senior-coding-app.6.answers.1" },
          { id: 2, text: "attestation.user-tests-list.senior-coding-app.6.answers.2" },
        ],
        correctAnswerId: 2
      },
      {
        image: "loading-tasks.png",
        question: "attestation.user-tests-list.senior-coding-app.7.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.senior-coding-app.7.answers.0" },
          { id: 1, text: "attestation.user-tests-list.senior-coding-app.7.answers.1" },
          { id: 2, text: "attestation.user-tests-list.senior-coding-app.7.answers.2" },
          { id: 3, text: "attestation.user-tests-list.senior-coding-app.7.answers.3" },
        ],
        correctAnswerId: 2
      },
      {
        image: "no-tasks.png",
        question: "attestation.user-tests-list.senior-coding-app.8.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.senior-coding-app.8.answers.0" },
          { id: 1, text: "attestation.user-tests-list.senior-coding-app.8.answers.1" },
          { id: 2, text: "attestation.user-tests-list.senior-coding-app.8.answers.2" },
        ],
        correctAnswerId: 1
      },

      // {
      //   question: "",
      //   answerOptions: [
      //     { id: 0, text: "" },
      //     { id: 1, text: "" },
      //     { id: 2, text: "" },
      //   ],
      //   correctAnswerId: 1
      // }
    ]
  },
  {
    id: "safety-protocols",
    testName: "safetyProtocolsTest",
    title: "attestation.tutorials-titles.safety-protocols",
    video: {
      bfh: "https://www.loom.com/embed/4e4de74968a94766be24154b5d099b50",
      neighborhood: "https://www.loom.com/embed/190e961d151047099e6f29ee096b6ea2"
    },
    presentations: [
      { title: "nav.big-family-house", url: "https://www.beautiful.ai/player/-MyRrCjENa_UKTbjoctQ", service: "bfh" },
      { title: "MOK Latvija", url: "https://www.beautiful.ai/player/-MyRr9n_06bE4toXl6ZY", service: "neighborhood" },
    ],
    test: [
      {
        question: "attestation.user-tests-list.safety-protocols.0.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.safety-protocols.0.answers.0" },
          { id: 1, text: "attestation.user-tests-list.safety-protocols.0.answers.1" },
          { id: 2, text: "attestation.user-tests-list.safety-protocols.0.answers.2" },
        ],
        correctAnswerId: 0
      },
      {
        question: "attestation.user-tests-list.safety-protocols.1.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.safety-protocols.1.answers.0" },
          { id: 1, text: "attestation.user-tests-list.safety-protocols.1.answers.1" },
          { id: 2, text: "attestation.user-tests-list.safety-protocols.1.answers.2" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.safety-protocols.2.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.safety-protocols.2.answers.0" },
          { id: 1, text: "attestation.user-tests-list.safety-protocols.2.answers.1" },
          { id: 2, text: "attestation.user-tests-list.safety-protocols.2.answers.2" },
        ],
        correctAnswerId: 2
      },
      {
        question: "attestation.user-tests-list.safety-protocols.3.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.safety-protocols.3.answers.0" },
          { id: 1, text: "attestation.user-tests-list.safety-protocols.3.answers.1" },
          { id: 2, text: "attestation.user-tests-list.safety-protocols.3.answers.2" },
        ],
        correctAnswerId: 0
      },
      {
        question: "attestation.user-tests-list.safety-protocols.4.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.safety-protocols.4.answers.0" },
          { id: 1, text: "attestation.user-tests-list.safety-protocols.4.answers.1" },
          { id: 2, text: "attestation.user-tests-list.safety-protocols.4.answers.2" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.safety-protocols.5.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.safety-protocols.5.answers.0" },
          { id: 1, text: "attestation.user-tests-list.safety-protocols.5.answers.1" },
          { id: 2, text: "attestation.user-tests-list.safety-protocols.5.answers.2" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.safety-protocols.6.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.safety-protocols.6.answers.0" },
          { id: 1, text: "attestation.user-tests-list.safety-protocols.6.answers.1" },
          { id: 2, text: "attestation.user-tests-list.safety-protocols.6.answers.2" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.safety-protocols.7.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.safety-protocols.7.answers.0" },
          { id: 1, text: "attestation.user-tests-list.safety-protocols.7.answers.1" },
          { id: 2, text: "attestation.user-tests-list.safety-protocols.7.answers.2" },
        ],
        correctAnswerId: 2
      },
      {
        question: "attestation.user-tests-list.safety-protocols.8.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.safety-protocols.8.answers.0" },
          { id: 1, text: "attestation.user-tests-list.safety-protocols.8.answers.1" },
          { id: 2, text: "attestation.user-tests-list.safety-protocols.8.answers.2" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.safety-protocols.9.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.safety-protocols.9.answers.0" },
          { id: 1, text: "attestation.user-tests-list.safety-protocols.9.answers.1" },
          { id: 2, text: "attestation.user-tests-list.safety-protocols.9.answers.2" },
        ],
        correctAnswerId: 2
      },
      {
        question: "attestation.user-tests-list.safety-protocols.10.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.safety-protocols.10.answers.0" },
          { id: 1, text: "attestation.user-tests-list.safety-protocols.10.answers.1" },
          { id: 2, text: "attestation.user-tests-list.safety-protocols.10.answers.2" },
        ],
        correctAnswerId: 2
      },
      {
        question: "attestation.user-tests-list.safety-protocols.11.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.safety-protocols.11.answers.0" },
          { id: 1, text: "attestation.user-tests-list.safety-protocols.11.answers.1" },
          { id: 2, text: "attestation.user-tests-list.safety-protocols.11.answers.2" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.safety-protocols.12.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.safety-protocols.12.answers.0" },
          { id: 1, text: "attestation.user-tests-list.safety-protocols.12.answers.1" },
          { id: 2, text: "attestation.user-tests-list.safety-protocols.12.answers.2" },
        ],
        correctAnswerId: 2
      },
      {
        question: "attestation.user-tests-list.safety-protocols.13.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.safety-protocols.13.answers.0" },
          { id: 1, text: "attestation.user-tests-list.safety-protocols.13.answers.1" },
          { id: 2, text: "attestation.user-tests-list.safety-protocols.13.answers.2" },
        ],
        correctAnswerId: 2,
        isBfh: true
      },
      {
        question: "attestation.user-tests-list.safety-protocols.14.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.safety-protocols.14.answers.0" },
          { id: 1, text: "attestation.user-tests-list.safety-protocols.14.answers.1" },
          { id: 2, text: "attestation.user-tests-list.safety-protocols.14.answers.2" },
        ],
        correctAnswerId: 1,
        isBfh: true
      },
    ]
  },
  {
    id: "care-and-positioning",
    testName: "careAndPositioningTest",
    title: "attestation.tutorials-titles.care-and-positioning",
    presentations: [
      { title: "attestation.presentations.care-and-positioning.0", url: "https://www.beautiful.ai/player/-N1hA_N2cHEvYkFGpt5f/36" },
      { title: "attestation.presentations.care-and-positioning.1", url: "https://www.beautiful.ai/player/-N1hI26qwSmUFhlue1bS" },
      { title: "attestation.presentations.care-and-positioning.2", url: "https://www.beautiful.ai/player/-N1hK1ZMHYTQGYgex3sH" }
    ],
    test: [
      {
        question: "attestation.user-tests-list.care-and-positioning.0.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.care-and-positioning.0.answers.0" },
          { id: 1, text: "attestation.user-tests-list.care-and-positioning.0.answers.1" },
          { id: 2, text: "attestation.user-tests-list.care-and-positioning.0.answers.2" },
          { id: 3, text: "attestation.user-tests-list.care-and-positioning.0.answers.3" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.care-and-positioning.1.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.care-and-positioning.1.answers.0" },
          { id: 1, text: "attestation.user-tests-list.care-and-positioning.1.answers.1" },
          { id: 2, text: "attestation.user-tests-list.care-and-positioning.1.answers.2" },
        ],
        correctAnswerId: 0
      },
      {
        question: "attestation.user-tests-list.care-and-positioning.2.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.care-and-positioning.2.answers.0" },
          { id: 1, text: "attestation.user-tests-list.care-and-positioning.2.answers.1" },
          { id: 2, text: "attestation.user-tests-list.care-and-positioning.2.answers.2" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.care-and-positioning.3.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.care-and-positioning.3.answers.0" },
          { id: 1, text: "attestation.user-tests-list.care-and-positioning.3.answers.1" },
          { id: 2, text: "attestation.user-tests-list.care-and-positioning.3.answers.2" },
          { id: 3, text: "attestation.user-tests-list.care-and-positioning.3.answers.3" },
        ],
        correctAnswerId: 2
      },
      {
        question: "attestation.user-tests-list.care-and-positioning.4.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.care-and-positioning.4.answers.0" },
          { id: 1, text: "attestation.user-tests-list.care-and-positioning.4.answers.1" },
          { id: 2, text: "attestation.user-tests-list.care-and-positioning.4.answers.2" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.care-and-positioning.5.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.care-and-positioning.5.answers.0" },
          { id: 1, text: "attestation.user-tests-list.care-and-positioning.5.answers.1" },
          { id: 2, text: "attestation.user-tests-list.care-and-positioning.5.answers.2" },
        ],
        correctAnswerId: 2
      },
      {
        question: "attestation.user-tests-list.care-and-positioning.6.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.care-and-positioning.6.answers.0" },
          { id: 1, text: "attestation.user-tests-list.care-and-positioning.6.answers.1" },
          { id: 2, text: "attestation.user-tests-list.care-and-positioning.6.answers.2" },
          { id: 3, text: "attestation.user-tests-list.care-and-positioning.6.answers.3" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.care-and-positioning.7.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.care-and-positioning.7.answers.0" },
          { id: 1, text: "attestation.user-tests-list.care-and-positioning.7.answers.1" },
          { id: 2, text: "attestation.user-tests-list.care-and-positioning.7.answers.2" },
          { id: 3, text: "attestation.user-tests-list.care-and-positioning.7.answers.3" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.care-and-positioning.8.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.care-and-positioning.8.answers.0" },
          { id: 1, text: "attestation.user-tests-list.care-and-positioning.8.answers.1" },
        ],
        correctAnswerId: 0
      },
      {
        question: "attestation.user-tests-list.care-and-positioning.9.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.care-and-positioning.9.answers.0" },
          { id: 1, text: "attestation.user-tests-list.care-and-positioning.9.answers.1" }
        ],
        correctAnswerId: 0
      },
      {
        question: "attestation.user-tests-list.care-and-positioning.10.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.care-and-positioning.10.answers.0" },
          { id: 1, text: "attestation.user-tests-list.care-and-positioning.10.answers.1" },
          { id: 2, text: "attestation.user-tests-list.care-and-positioning.10.answers.2" }
        ],
        correctAnswerId: 0
      },
      {
        question: "attestation.user-tests-list.care-and-positioning.11.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.care-and-positioning.11.answers.0" },
          { id: 1, text: "attestation.user-tests-list.care-and-positioning.11.answers.1" },
          { id: 2, text: "attestation.user-tests-list.care-and-positioning.11.answers.2" }
        ],
        correctAnswerId: 2
      },
      {
        question: "attestation.user-tests-list.care-and-positioning.12.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.care-and-positioning.12.answers.0" },
          { id: 1, text: "attestation.user-tests-list.care-and-positioning.12.answers.1" },
          { id: 2, text: "attestation.user-tests-list.care-and-positioning.12.answers.2" }
        ],
        correctAnswerId: 2
      },
      {
        question: "attestation.user-tests-list.care-and-positioning.13.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.care-and-positioning.13.answers.0" },
          { id: 1, text: "attestation.user-tests-list.care-and-positioning.13.answers.1" }
        ],
        correctAnswerId: 0,
        disabled: true
      },
      {
        question: "attestation.user-tests-list.care-and-positioning.14.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.care-and-positioning.14.answers.0" },
          { id: 1, text: "attestation.user-tests-list.care-and-positioning.14.answers.1" },
          { id: 2, text: "attestation.user-tests-list.care-and-positioning.14.answers.2" },
        ],
        correctAnswerId: 2
      },
      {
        question: "attestation.user-tests-list.care-and-positioning.15.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.care-and-positioning.15.answers.0" },
          { id: 1, text: "attestation.user-tests-list.care-and-positioning.15.answers.1" },
          { id: 2, text: "attestation.user-tests-list.care-and-positioning.15.answers.2" },
        ],
        correctAnswerId: 1
      },
    ]
  },
  // {
  //   id: "work-with-clients",
  //   testName:"socializingTest",
  //   title: "attestation.tutorials-titles.socializing",
  //   presentations: [
  //     { url: "https://www.beautiful.ai/player/-MyRulNxTgJ9pah8wUSy" }
  //   ],
  //   test: [
  //     {
  //       question: "attestation.user-tests-list.work-with-clients.0.question",
  //       answerOptions: [
  //         { id: 0, text: "attestation.user-tests-list.work-with-clients.0.answers.0" },
  //         { id: 1, text: "attestation.user-tests-list.work-with-clients.0.answers.1" },
  //         { id: 2, text: "attestation.user-tests-list.work-with-clients.0.answers.2" },
  //       ],
  //       correctAnswerId: 0
  //     },
  //     {
  //       question: "attestation.user-tests-list.work-with-clients.1.question",
  //       answerOptions: [
  //         { id: 0, text: "attestation.user-tests-list.work-with-clients.1.answers.0" },
  //         { id: 1, text: "attestation.user-tests-list.work-with-clients.1.answers.1" },
  //         { id: 2, text: "attestation.user-tests-list.work-with-clients.1.answers.2" },
  //       ],
  //       correctAnswerId: 2
  //     },
  //     {
  //       question: "attestation.user-tests-list.work-with-clients.2.question",
  //       answerOptions: [
  //         { id: 0, text: "attestation.user-tests-list.work-with-clients.2.answers.0" },
  //         { id: 1, text: "attestation.user-tests-list.work-with-clients.2.answers.1" },
  //         { id: 2, text: "attestation.user-tests-list.work-with-clients.2.answers.2" },
  //       ],
  //       correctAnswerId: 1
  //     },
  //     {
  //       question: "attestation.user-tests-list.work-with-clients.3.question",
  //       answerOptions: [
  //         { id: 0, text: "attestation.user-tests-list.work-with-clients.3.answers.0" },
  //         { id: 1, text: "attestation.user-tests-list.work-with-clients.3.answers.1" },
  //         { id: 2, text: "attestation.user-tests-list.work-with-clients.3.answers.2" },
  //       ],
  //       correctAnswerId: 2
  //     },
  //     {
  //       question: "attestation.user-tests-list.work-with-clients.4.question",
  //       answerOptions: [
  //         { id: 0, text: "attestation.user-tests-list.work-with-clients.4.answers.0" },
  //         { id: 1, text: "attestation.user-tests-list.work-with-clients.4.answers.1" },
  //         { id: 2, text: "attestation.user-tests-list.work-with-clients.4.answers.2" },
  //       ],
  //       correctAnswerId: 0
  //     },
  //     {
  //       question: "attestation.user-tests-list.work-with-clients.5.question",
  //       answerOptions: [
  //         { id: 0, text: "attestation.user-tests-list.work-with-clients.5.answers.0" },
  //         { id: 1, text: "attestation.user-tests-list.work-with-clients.5.answers.1" },
  //         { id: 2, text: "attestation.user-tests-list.work-with-clients.5.answers.2" },
  //       ],
  //       correctAnswerId: 1
  //     },
  //     {
  //       question: "attestation.user-tests-list.work-with-clients.6.question",
  //       answerOptions: [
  //         { id: 0, text: "attestation.user-tests-list.work-with-clients.6.answers.0" },
  //         { id: 1, text: "attestation.user-tests-list.work-with-clients.6.answers.1" },
  //         { id: 2, text: "attestation.user-tests-list.work-with-clients.6.answers.2" },
  //       ],
  //       correctAnswerId: 0
  //     },
  //     {
  //       question: "attestation.user-tests-list.work-with-clients.7.question",
  //       answerOptions: [
  //         { id: 0, text: "attestation.user-tests-list.work-with-clients.7.answers.0" },
  //         { id: 1, text: "attestation.user-tests-list.work-with-clients.7.answers.1" },
  //         { id: 2, text: "attestation.user-tests-list.work-with-clients.7.answers.2" },
  //       ],
  //       correctAnswerId: 0
  //     },
  //     {
  //       question: "attestation.user-tests-list.work-with-clients.8.question",
  //       answerOptions: [
  //         { id: 0, text: "attestation.user-tests-list.work-with-clients.8.answers.0" },
  //         { id: 1, text: "attestation.user-tests-list.work-with-clients.8.answers.1" },
  //         { id: 2, text: "attestation.user-tests-list.work-with-clients.8.answers.2" },
  //       ],
  //       correctAnswerId: 0
  //     },
  //     {
  //       question: "attestation.user-tests-list.work-with-clients.9.question",
  //       answerOptions: [
  //         { id: 0, text: "attestation.user-tests-list.work-with-clients.9.answers.0" },
  //         { id: 1, text: "attestation.user-tests-list.work-with-clients.9.answers.1" },
  //         { id: 2, text: "attestation.user-tests-list.work-with-clients.9.answers.2" },
  //       ],
  //       correctAnswerId: 1
  //     },
  //     {
  //       question: "attestation.user-tests-list.work-with-clients.10.question",
  //       answerOptions: [
  //         { id: 0, text: "attestation.user-tests-list.work-with-clients.10.answers.0" },
  //         { id: 1, text: "attestation.user-tests-list.work-with-clients.10.answers.1" },
  //         { id: 2, text: "attestation.user-tests-list.work-with-clients.10.answers.2" },
  //       ],
  //       correctAnswerId: 0
  //     },
  //     {
  //       question: "attestation.user-tests-list.work-with-clients.11.question",
  //       answerOptions: [
  //         { id: 0, text: "attestation.user-tests-list.work-with-clients.11.answers.0" },
  //         { id: 1, text: "attestation.user-tests-list.work-with-clients.11.answers.1" },
  //         { id: 2, text: "attestation.user-tests-list.work-with-clients.11.answers.2" },
  //       ],
  //       correctAnswerId: 2
  //     },
  //   ]
  // },
  {
    id: "bfh-utilities",
    testName: "bfhUtilitiesTest",
    title: "attestation.tutorials-titles.bfh",
    presentations: [
      { title: "attestation.tutorials-titles.bfh", url: "https://www.beautiful.ai/player/-MsUXVW_C5xQZq0oVE4g" }
    ],
    video: "https://www.loom.com/embed/0fcf397efc544e4ba6c4850977097120",
    test: [
      {
        question: "attestation.user-tests-list.bfh-utilities.0.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.bfh-utilities.0.answers.0" },
          { id: 1, text: "attestation.user-tests-list.bfh-utilities.0.answers.1" },
          { id: 2, text: "attestation.user-tests-list.bfh-utilities.0.answers.2" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.bfh-utilities.1.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.bfh-utilities.1.answers.0" },
          { id: 1, text: "attestation.user-tests-list.bfh-utilities.1.answers.1" },
          { id: 2, text: "attestation.user-tests-list.bfh-utilities.1.answers.2" },
        ],
        correctAnswerId: 2
      },
      {
        question: "attestation.user-tests-list.bfh-utilities.2.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.bfh-utilities.2.answers.0" },
          { id: 1, text: "attestation.user-tests-list.bfh-utilities.2.answers.1" },
          { id: 2, text: "attestation.user-tests-list.bfh-utilities.2.answers.2" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.bfh-utilities.3.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.bfh-utilities.3.answers.0" },
          { id: 1, text: "attestation.user-tests-list.bfh-utilities.3.answers.1" },
          { id: 2, text: "attestation.user-tests-list.bfh-utilities.3.answers.2" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.bfh-utilities.4.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.bfh-utilities.4.answers.0" },
          { id: 1, text: "attestation.user-tests-list.bfh-utilities.4.answers.1" },
          { id: 2, text: "attestation.user-tests-list.bfh-utilities.4.answers.2" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.bfh-utilities.5.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.bfh-utilities.5.answers.0" },
          { id: 1, text: "attestation.user-tests-list.bfh-utilities.5.answers.1" },
          { id: 2, text: "attestation.user-tests-list.bfh-utilities.5.answers.2" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.bfh-utilities.6.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.bfh-utilities.6.answers.0" },
          { id: 1, text: "attestation.user-tests-list.bfh-utilities.6.answers.1" },
          { id: 2, text: "attestation.user-tests-list.bfh-utilities.6.answers.2" },
        ],
        correctAnswerId: 1
      },
      {
        question: "attestation.user-tests-list.bfh-utilities.7.question",
        answerOptions: [
          { id: 0, text: "attestation.user-tests-list.bfh-utilities.7.answers.0" },
          { id: 1, text: "attestation.user-tests-list.bfh-utilities.7.answers.1" },
          { id: 2, text: "attestation.user-tests-list.bfh-utilities.7.answers.2" },
        ],
        correctAnswerId: 1
      },

    ]
  },
];

export const attestationTestsNames = [
  "seniorGroupBasicsTest", "seniorCodingAppTest", "bfhUtilitiesTest", "safetyProtocolsTest", "careAndPositioningTest"
];

export const coordinators = [
  {
    id: 0,
    serviceName: "MOK LAT",
    service: "neighborhood",
    phoneNumber: "27660310",
    image: "Ola.jpg",
    duties: "contacts.duties.0"
  },
  {
    id: 2,
    serviceName: "MOK LAT",
    service: "neighborhood",
    phoneNumber: "27746525",
    image: "Zana.jpg",
    duties: "contacts.duties.2"
  },
  {
    id: 3,
    serviceName: "MOK LAT",
    service: "neighborhood",
    phoneNumber: "28863655",
    image: "Laila.jpg",
    duties: "contacts.duties.3"
  },
  {
    id: 4,
    serviceName: "MOK LAT",
    service: "neighborhood",
    phoneNumber: null,
    image: "Iren.jpg"
  },
  {
    id: 5,
    serviceName: "BFH",
    service: "bfh",
    phoneNumber: "20210947",
  },
  {
    id: 6,
    serviceName: "BFH",
    service: "bfh",
    phoneNumber: "27746525",
  },
]

export const politenessPhrases = [
  { id: "0" },
  { id: "1", description: true },
  { id: "2" },
  { id: "3", description: true },
  { id: "4" },
  { id: "5" },
  { id: "6" },
  { id: "7", description: true },
]