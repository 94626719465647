<template>
  <!-- prepare report dialog -->
  <v-dialog
    v-model="dialog"
    hide-overlay
    persistent
    width="300"
    v-if="$root.mainSaving"
  >
    <v-card color="primary" dark>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-center"> Saglabā ... </v-col>
        </v-row>

        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SavingData",
  data() {
    return {
      dialog: true,
    };
  },
};
</script>
