//delete service worker
//https://kevincox.ca/2020/11/03/removing-a-service-worker/
//import { register } from "register-service-worker";
/* eslint-disable no-console */
console.log("Cleanup Service Worker Starting");

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      console.log("unregister");
      registration.unregister();
    }
  });
  // clear cache of service worker
  caches.keys().then((keyList) => {
    return Promise.all(
      keyList.map((key) => {
        return caches.delete(key);
      })
    );
  });
}

// // check if browser supports SW and if in production mode
// if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
//   register(`${process.env.BASE_URL}service-worker.js`, {
//     ready() {
//       console.log(
//         "App is being served from cache by a service worker.\n" +
//           "For more details, visit https://goo.gl/AFskqB"
//       );
//     },
//     cached() {
//       console.log("Content has been cached for offline use.");
//     },
//     updatefound() {
//       console.log("New content is downloading.");
//     },
//     updated(registration) {
//       console.log("New content is available; please refresh.");
//       const confirmationResult = confirm(
//         "Pieejams atjauninājums. Atjaunot lapu!"
//       );
//       if (confirmationResult) {
//         registration.waiting.postMessage({ action: "skipWaiting" });
//       }
//     },
//     offline() {
//       console.log(
//         "No internet connection found. App is running in offline mode."
//       );
//     },
//     error(error) {
//       console.error("Error during service worker registration:", error);
//     },
//   });

//   let refreshing;
//   navigator.serviceWorker.addEventListener("controllerchange", (e) => {
//     if (refreshing) return;
//     window.location.reload();
//     refreshing = true;
//   });
// }
