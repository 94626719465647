<template>
  <div id="app">
    <v-app id="inspire">
      <v-navigation-drawer app v-model="drawer" v-if="userId != null">
        <v-list dense>
          <!-- mani uzdevumi -->
          <v-list-item :to="'/'">
            <v-list-item-action>
              <v-icon>assignment_turned_in</v-icon>
            </v-list-item-action>
            <v-list-item-title>0. {{ $t("nav.my-tasks") }}</v-list-item-title>
          </v-list-item>

          <v-list-item :to="'/clients'">
            <v-list-item-action>
              <v-icon>face</v-icon>
            </v-list-item-action>
            <v-list-item-title>1. {{ $t("nav.clients") }}</v-list-item-title>
          </v-list-item>

          <v-list-item :to="'/calendar'">
            <v-list-item-action>
              <v-icon>event</v-icon>
            </v-list-item-action>
            <v-list-item-title>2. {{ $t("nav.calendar") }}</v-list-item-title>
          </v-list-item>
          <v-list-item :to="'/worker-suggestions-register'">
            <v-list-item-action>
              <v-icon>psychology</v-icon>
            </v-list-item-action>
            <v-list-item-title
              >3. {{ $t("general.my-suggestions") }}</v-list-item-title
            >
          </v-list-item>

          <v-list-group prepend-icon="help">
            <template v-slot:activator>
              <v-list-item-title>4. {{ $t("nav.help") }}</v-list-item-title>
            </template>
            <v-list-item :to="'/helpdescriptions'">
              <v-list-item-title>
                4.1 {{ $t("nav.what-to-do") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item :to="'/helpemergency'">
              <v-list-item-title>
                4.2 {{ $t("nav.first-aid") }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item :to="'/helpvideoadl'">
              <v-list-item-title> 4.3 {{ $t("nav.care") }} </v-list-item-title>
            </v-list-item>
            <v-list-item :to="'/yourtaxes'">
              <v-list-item-title>
                4.4 {{ $t("users.your-taxes") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="'/politenessPhrases'"
              v-if="showPolitenessPhrases()"
            >
              <v-list-item-title>
                4.5 {{ $t("nav.politeness-phrases") }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-group prepend-icon="book">
            <template v-slot:activator>
              <v-list-item-title
                >5. {{ $t("attestation.training") }}</v-list-item-title
              >
            </template>
            <template v-for="(tutorial, index) in allTutorials">
              <v-list-item
                :to="`/tutorials/${tutorial.id}`"
                :key="index"
                v-if="showTutorial(tutorial)"
              >
                <v-list-item-title>
                  5.{{ index + 1 }} {{ $t(tutorial.title) }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
          <v-list-group prepend-icon="history_edu" v-if="showTests">
            <template v-slot:activator>
              <v-list-item-title
                >5. {{ $t("attestation.tests") }}</v-list-item-title
              >
            </template>
            <template v-for="(tutorial, index) in allTutorials">
              <v-list-item
                :to="`/tests/${tutorial.id}`"
                :key="index"
                v-if="showTutorial(tutorial)"
              >
                <v-list-item-title>
                  5.{{ index + 1 }}.{{ $t("attestation.test") }} -
                  {{ $t(tutorial.title) }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
          <a
            :href="fullVersionUrl"
            v-if="$root.admin || $root.manager"
            style="text-decoration: none"
          >
            <v-list-item>
              <v-list-item-action>
                <v-icon>open_in_new</v-icon>
              </v-list-item-action>
              <v-list-item-title
                >{{ showTests ? "7" : "6" }}.
                {{ $t("global.sc7-full-version") }}</v-list-item-title
              >
            </v-list-item>
          </a>
          <v-list-item :to="'/contacts'">
            <v-list-item-action>
              <v-icon> contact_page</v-icon>
            </v-list-item-action>
            <v-list-item-title
              >{{ showTests ? "8" : "7" }}.
              {{ $t("nav.contacts") }}</v-list-item-title
            >
          </v-list-item>
          <!-- sign out -->
          <v-list-group prepend-icon="power_settings_new">
            <template v-slot:activator>
              <v-list-item-title
                >{{ showTests ? "9" : "8" }}.
                {{ $t("nav.log-out") }}</v-list-item-title
              >
            </template>
            <v-list-item v-if="user != ''">
              <span>{{ user.displayName }}</span>
              <v-btn icon v-on:click="logOut">
                <v-icon>power_settings_new</v-icon>
              </v-btn>
            </v-list-item>
          </v-list-group>

          <v-list-item>
            <small>v. {{ $root.scversion }}</small>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app color="primary" dark>
        <v-btn icon @click.stop="drawer = !drawer" color="white">
          <v-icon>menu</v-icon>
        </v-btn>

        <v-toolbar-title>
          <span v-if="environment != null">
            <v-icon color="error">bug_report</v-icon>
            {{ environment }}
            <v-icon color="error">bug_report</v-icon>
          </span>
          SC7s
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <!-- LANGUAGE -->
        <span>
          <v-menu offset-y tile content>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" outlined text>
                <v-icon left>translate</v-icon>
                {{ $i18n.locale }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(lang, index) in languages" :key="index">
                <v-list-item-content @click="changeLocale(lang)">
                  <v-list-item-title>{{ lang }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </span>
        <!-- name -->
        <span v-if="user != ''" class="userName">{{ user.displayName }}</span>
        <!-- back button -->
        <v-btn icon small outlined @click="$router.go(-1)">
          <v-icon>arrow_back</v-icon>
        </v-btn>
      </v-app-bar>
      <!-- render view -->
      <v-main v-if="!blockedUser">
        <v-container fluid>
          <v-fade-transition mode="out-in">
            <router-view :key="$route.fullPath"> </router-view>
          </v-fade-transition>
          <saving-data> </saving-data>
        </v-container>
      </v-main>
      <v-main v-else>
        <v-card class="mx-auto" max-width="480">
          <v-card-title>
            <v-col cols="12" class="text-center">
              {{ $t("users.blocked") }} - {{ user.email }}
            </v-col>
          </v-card-title>
          <v-card-subtitle class="text-center">
            {{ $t("users.to-unblock") }}
          </v-card-subtitle>
        </v-card>
      </v-main>

      <v-footer color="secondary" app inset>
        <span>
          <small>&copy; 2018 - {{ new Date().getFullYear() }}</small>
          <!-- {{ $root.currentUserData.displayName }} -->
        </span>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import { db, auth } from "@/main";
import { env } from "@/helpers/firebaseConfig.js";
//helpers
import { formatDateInRiga } from "@/helpers/dayjsDates.js";
import { tutorials } from "./helpers/constants";
import SavingData from "@/components/utilities/SavingData.vue";
import { includes, isEqual } from "lodash";
import {
  checkIfDatesAreEqual,
  getDaysBetween,
  timestampToDate,
} from "./helpers/dateTimeConvertingFunctions";

// import { useMainStore } from "@/store/mainStore";

export default {
  // setup(){
  // const main =  useMainStore();
  // // acces it directly in setup
  // // don't have to use  main.counter
  //   main.counter
  //   // or return it to access it in template
  //   return { main }
  // },
  currentUser: "",
  components: { SavingData },
  data: () => ({
    drawer: null,
    environment: null,
    user: {
      role: {},
    },
    formatDate: formatDateInRiga(new Date()),
    blockedUser: false,
    userId: null,
    currentUserData: "",
    languages: ["LV", "LT", "EE", "EN", "RU", "FR", "IT"],
    fullVersionUrl: "https://sc7-main.web.app/",
    isFocused: true,
    lastTimeActive: new Date(),
    allTutorials: tutorials,
    showTests: false,
  }),
  watch: {
    user: function (userData) {
      //adjust for blocked users
      if (typeof userData.role != "undefined") {
        if (Object.keys(userData.role).length > 0) {
          this.blockedUser = false;
        } else {
          this.blockedUser = true;
        }
      }
    },
  },
  async created() {
    let vm = this;
    if (this.isMobile()) {
      window.onfocus = function () {
        if (!vm.isFocused) {
          const secondsDifference = vm.calculateSecondsBetweenTimes(
            vm.lastTimeActive,
            new Date()
          );
          if (secondsDifference > 120) {
            window.location.reload();
          }
        }
      };
      window.onblur = function () {
        if (vm.isFocused) {
          vm.lastTimeActive = new Date();
          vm.isFocused = false;
        }
      };
    }
    //if testing environment
    if (env == "testing") {
      vm.environment = "TESTING";
      vm.fullVersionUrl = "https://sc7test-full.web.app/";
    }

    let user = auth.currentUser;

    if (user) {
      vm.user = user;
      vm.userId = user.uid;

      this.getCurrentUserData(vm.userId);
      await vm.updateLocalUserData(user);
      localStorage.setItem("token", JSON.stringify(user.refreshToken));
      db.collection("attestations")
        .where("userId", "==", this.$root.currentUserData.userId)
        .where("isActive", "==", true)
        .onSnapshot((snapShot) => {
          if (!snapShot.empty) {
            const data = snapShot.docs[0].data();
            this.showTests = _.isNil(data.finishedDate);
          }
        });
    }
  },
  methods: {
    showPolitenessPhrases() {
      return (
        this.$root.currentUserData &&
        this.$root.currentUserData.aiderServices &&
        this.$root.currentUserData.aiderServices.includes("neighborhood")
      );
    },
    async getShowTests(user) {
      const attestationResponse = await db
        .collection("attestations")
        .where("userId", "==", user.userId)
        .where("isActive", "==", true)
        .get();
      console.log(attestationResponse.docs.length, user.userId);
      if (!_.isEmpty(attestationResponse.docs)) {
        this.showTests = true;
      }
    },
    showTutorial(tutorial) {
      const bfhTutorials = ["socializingTest", "bfhUtilitiesTest"];
      const isOnlyBfhTutorial = includes(bfhTutorials, tutorial.testName);
      let showTut = true;
      if (
        isOnlyBfhTutorial &&
        !includes(this.$root.currentUserData.aiderServices, "bfh")
      ) {
        showTut = false;
      }
      return showTut;
    },
    isMobile() {
      return screen.width <= 760;
    },
    calculateSecondsBetweenTimes(date1, date2) {
      const diffTime = Math.abs(date2 - date1);
      const diffSeconds = diffTime / 1000;
      return diffSeconds;
    },
    changeLocale(lang) {
      this.$i18n.locale = lang.toLowerCase();
      localStorage.setItem("sc7-locale", JSON.stringify(lang.toLowerCase()));
    },
    async updateLocalUserData(user) {
      var vm = this;
      const uid = user.uid;
      //const uid = 'eBmQRcVClsh7V5XXgdVeQmCW4nI3'; //FOR TEST
      var getUser = await db.collection("users").doc(uid).get();
      if (getUser.exists) {
        let storeUserObj = getUser.data();
        storeUserObj["userId"] = uid;
        //save local
        vm.user = Object.assign({}, storeUserObj);
        this.updateRolesAndData(storeUserObj);
        localStorage.setItem(
          user.uid + "-userdata",
          JSON.stringify(storeUserObj)
        );
        //detect device data
        vm.getDeviceData(storeUserObj);
      } else {
        console.log("user doesnt exist");
      }
    },
    getDeviceData(storeUserObj) {
      let vm = this;
      //update user broser data
      let user = storeUserObj.displayName;
      let ua = window.navigator.userAgent;

      let activity = `${this.$root.scversion}; ${ua}`;
      const logKey = `device-${vm.formatDate}-${auth.currentUser.uid}-${activity}`;

      const hasLog = localStorage.getItem(logKey);
      if (hasLog === null) {
        //add log to db
        this.logActivity("Device", user, activity);
        //set in local storage
        localStorage.setItem(logKey, activity);
      }
    },
    async logOut() {
      var vm = this;
      var conf = confirm(
        `${vm.user.displayName}, ${vm.$t("global.logout-confirmation")}`
      );
      if (conf) {
        try {
          localStorage.setItem("token", null);
          await auth.signOut();
          this.user = "";
          location.reload();
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style>
table tr:nth-child(odd) {
  background-color: rgb(235, 233, 233);
}
h1 {
  color: gray;
}
.userName {
  margin-right: 10px;
}
/* task coloring */
.task-group-1 {
  color: #9900ff;
}
.task-group-2 {
  color: #4a86e8;
}
.task-group-4 {
  color: #74cabe;
}
.task-group-3 {
  color: #f6b26b;
}
.task-group-5 {
  color: #c49d28;
}
.task-group-6 {
  color: #6aa84f;
}
</style>

<style lang="sass" scoped>
$menu-content-elevation: 0
</style>
